import React, { useState } from "react";

const AddNewSale = ({ onAdd }) => {
  const [formData, setFormData] = useState({
    customer_name: "",
    product_name: "",
    quantity: "",
    unit_price: "",
    sales_revenue: 0,
    sale_date: new Date().toISOString().split("T")[0],
  });

  // When updating state in AddNewSale.js
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "unit_price" ? parseFloat(value) : value;

    setFormData((prevData) => ({ ...prevData, [name]: newValue }));

    // Calculate sales revenue only if quantity and unit price are valid
    if (name === "quantity" || name === "unit_price") {
      const quantity = parseInt(formData.quantity || 0);
      const unit_price = parseFloat(newValue || 0); // Using newValue here
      const sales_revenue = quantity * unit_price;
      setFormData((prevData) => ({ ...prevData, sales_revenue }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(formData);
    setFormData({
      customer_name: "",
      product_name: "",
      quantity: 1,
      unit_price: 0,
      sales_revenue: 0,
      sale_date: new Date().toISOString().split("T")[0],
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="customer_name"
        value={formData.customer_name}
        onChange={handleChange}
        placeholder="Customer Name"
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <input
        type="text"
        name="product_name"
        value={formData.product_name}
        onChange={handleChange}
        placeholder="Product Name"
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <input
        type="number"
        name="quantity"
        value={formData.quantity}
        onChange={handleChange}
        placeholder="Quantity"
        min="1"
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <input
        type="number"
        name="unit_price"
        value={formData.unit_price}
        onChange={handleChange}
        placeholder="Unit Price"
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <input
        type="date"
        name="sale_date"
        value={formData.sale_date}
        onChange={handleChange}
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <p className="mb-2">
        Sales Revenue: ${formData.sales_revenue.toFixed(2)}
      </p>
      <button type="submit" className="p-2 bg-green-600 text-white rounded">
        Add Sale
      </button>
    </form>
  );
};

export default AddNewSale;
