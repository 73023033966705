import React, { useState, useEffect } from "react";

const EditSale = ({ currentSale, onUpdate }) => {
  const [formData, setFormData] = useState({ ...currentSale });

  useEffect(() => {
    setFormData({ ...currentSale });
  }, [currentSale]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Calculate sales revenue whenever quantity or unit price changes
    if (name === "quantity" || name === "unit_price") {
      const quantity = parseInt(value || 0);
      const unit_price = parseFloat(formData.unit_price || 0);
      const sales_revenue = quantity * unit_price;
      setFormData((prevData) => ({ ...prevData, sales_revenue }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="customer_name"
        value={formData.customer_name || ""}
        onChange={handleChange}
        placeholder="Customer Name"
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <input
        type="text"
        name="product_name"
        value={formData.product_name || ""}
        onChange={handleChange}
        placeholder="Product Name"
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <input
        type="number"
        name="quantity"
        value={formData.quantity || ""}
        onChange={handleChange}
        placeholder="Quantity"
        min="1"
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <input
        type="number"
        name="unit_price"
        value={formData.unit_price || ""}
        onChange={handleChange}
        placeholder="Unit Price"
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <p className="mb-2">
        Sales Revenue: ${formData.sales_revenue.toFixed(2)}
      </p>
      <input
        type="date"
        name="sale_date"
        value={formData.sale_date || ""}
        onChange={handleChange}
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <button type="submit" className="p-2 bg-green-600 text-white rounded">
        Update Sale
      </button>
    </form>
  );
};

export default EditSale;
