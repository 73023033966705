import React, { useState } from "react";

const AddNewProduct = ({ onAdd }) => {
  const [newProduct, setNewProduct] = useState({
    product_id: "",
    product_name: "",
    product_category: "",
    description: "",
    unit_price: "",
    stock_quantity: "",
    reorder_level: "",
    status: "Available",
    date_added: new Date().toISOString().split("T")[0], // Set to today's date
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({
      ...newProduct,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Parse unit_price, stock_quantity, and reorder_level as numbers
    const parsedProduct = {
      ...newProduct,
      product_id: Date.now(), // Use timestamp as ID
      unit_price: parseFloat(newProduct.unit_price), // Ensure unit_price is a number
      stock_quantity: parseInt(newProduct.stock_quantity, 10), // Ensure stock_quantity is an integer
      reorder_level: parseInt(newProduct.reorder_level, 10), // Ensure reorder_level is an integer
    };

    onAdd(parsedProduct); // Call onAdd with the parsed product
    setNewProduct({
      product_id: "",
      product_name: "",
      product_category: "",
      description: "",
      unit_price: "",
      stock_quantity: "",
      reorder_level: "",
      status: "Available",
      date_added: new Date().toISOString().split("T")[0],
    });
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <input
        type="text"
        name="product_name"
        placeholder="Product Name"
        value={newProduct.product_name}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="text"
        name="product_category"
        placeholder="Category"
        value={newProduct.product_category}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <textarea
        name="description"
        placeholder="Description"
        value={newProduct.description}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="number"
        name="unit_price"
        placeholder="Unit Price"
        value={newProduct.unit_price}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="number"
        name="stock_quantity"
        placeholder="Stock Quantity"
        value={newProduct.stock_quantity}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="number"
        name="reorder_level"
        placeholder="Reorder Level"
        value={newProduct.reorder_level}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <button type="submit" className="p-2 bg-green-600 text-white rounded">
        Add Product
      </button>
    </form>
  );
};

export default AddNewProduct;
