import React, { useState, useEffect } from "react";
import AddNewSale from "../components/sales/AddNewSale";
import EditSale from "../components/sales/EditSale";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]); // State for filtered sales
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentSale, setCurrentSale] = useState(null);

  useEffect(() => {
    const loadSales = async () => {
      try {
        const response = await fetch("/dummysales.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setSales(data);
        setFilteredSales(data); // Set initial filtered sales to all sales
      } catch (error) {
        console.error("Error fetching sales:", error);
      }
    };

    loadSales();
  }, []);

  useEffect(() => {
    // Filter sales based on search term
    const results = sales.filter(
      (sale) =>
        sale.customer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSales(results);
  }, [searchTerm, sales]); // Re-run filter when search term or sales change

  const handleAddSale = (newSale) => {
    setSales((prevSales) => [...prevSales, newSale]);
    setIsAddModalOpen(false);
  };

  const handleDeleteSale = (index) => {
    const updatedSales = sales.filter((_, idx) => idx !== index);
    setSales(updatedSales);
  };

  const handleEditSale = (index) => {
    const saleToEdit = sales[index];
    if (saleToEdit) {
      setCurrentSale({ ...saleToEdit, index });
      setIsEditModalOpen(true);
    }
  };

  const handleUpdateSale = (updatedSale) => {
    const updatedSales = [...sales];
    updatedSales[currentSale.index] = updatedSale;
    setSales(updatedSales);
    setIsEditModalOpen(false);
    setCurrentSale(null);
  };

  return (
    <div className="h-full flex flex-col gap-4">
      <h2 className="text-2xl font-bold mb-4">Sales Page</h2>

      <input
        type="text"
        placeholder="Search by Customer or Product"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 p-2 border border-gray-300 rounded"
      />

      <button
        onClick={() => setIsAddModalOpen(true)}
        className="mb-4 p-2 bg-blue-600 text-white rounded flex items-center"
      >
        <AddIcon className="mr-2" /> Add New Sale
      </button>

      <table className="min-w-full border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 p-2">Sale ID</th>
            <th className="border border-gray-300 p-2">Customer Name</th>
            <th className="border border-gray-300 p-2">Product Name</th>
            <th className="border border-gray-300 p-2">Quantity</th>
            <th className="border border-gray-300 p-2">Unit Price</th>
            <th className="border border-gray-300 p-2">Sales Revenue</th>
            <th className="border border-gray-300 p-2">Sale Date</th>
            <th className="border border-gray-300 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredSales.map((sale, index) => (
            <tr key={index} className="hover:bg-gray-50">
              <td className="border border-gray-300 p-2">{index + 1}</td>
              <td className="border border-gray-300 p-2">
                {sale.customer_name}
              </td>
              <td className="border border-gray-300 p-2">
                {sale.product_name}
              </td>
              <td className="border border-gray-300 p-2">{sale.quantity}</td>
              <td>
                {typeof sale.unit_price === "number"
                  ? sale.unit_price.toFixed(2)
                  : "N/A"}
              </td>
              <td className="border border-gray-300 p-2">
                ${sale.sales_revenue.toFixed(2)}
              </td>
              <td className="border border-gray-300 p-2">{sale.sale_date}</td>
              <td className="border border-gray-300 p-2 flex space-x-2">
                <EditIcon
                  onClick={() => handleEditSale(index)}
                  className="cursor-pointer text-blue-600 hover:text-blue-800"
                />
                <DeleteIcon
                  onClick={() => handleDeleteSale(index)}
                  className="cursor-pointer text-red-600 hover:text-red-800"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Add New Sale */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-96">
            <h3 className="text-lg font-bold mb-4">Add New Sale</h3>
            <AddNewSale onAdd={handleAddSale} />
            <button
              onClick={() => setIsAddModalOpen(false)}
              className="mt-4 p-2 bg-red-600 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Modal for Edit Sale */}
      {isEditModalOpen && currentSale && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-96">
            <h3 className="text-lg font-bold mb-4">Edit Sale</h3>
            <EditSale currentSale={currentSale} onUpdate={handleUpdateSale} />
            <button
              onClick={() => setIsEditModalOpen(false)}
              className="mt-4 p-2 bg-red-600 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sales;
