import React from "react";
import { useSortableData } from "./useSortableData"; // Custom hook for sorting
import Table from "./Table"; //Table component
import ViewAllLink from "../menu/ViewAllLink";

//import images
import Camera from "../../assets/recent_order/camera.png";
import Dress from "../../assets/recent_order/dress.png";
import Oil from "../../assets/recent_order/oil.png";
import Purfume from "../../assets/recent_order/perfume.png";

// Sample data for recent orders
const recentOrdersData = [
  {
    trackingNo: "#876364",
    productName: "Camera Lens",
    price: "$178",
    totalOrder: "325",
    totalAmount: "$1,46,660",
    productImage: Camera,
  },
  {
    trackingNo: "#876368",
    productName: "Black Sleep Dress",
    price: "$14",
    totalOrder: "53",
    totalAmount: "$46,660",
    productImage: Dress,
  },
  {
    trackingNo: "#876412",
    productName: "Argan Oil",
    price: "$21",
    totalOrder: "78",
    totalAmount: "$3,46,676",
    productImage: Oil,
  },
  {
    trackingNo: "#876621",
    productName: "EAU DE Parfum",
    price: "$32",
    totalOrder: "98",
    totalAmount: "$3,46,981",
    productImage: Purfume,
  },
];

const RecentOrders = () => {
  // Use custom hook to handle sorting
  const { sortedItems, requestSort, sortConfig } =
    useSortableData(recentOrdersData);

  // Define columns for the table
  const columns = [
    //tracking no coloumn data
    {
      key: "trackingNo",
      label: "Tracking no",
      align: "text-left",
    },
    //product name coloumn data
    {
      key: "productName",
      label: "Product Name",
      align: "text-left",
      render: (
        value,
        row // Custom rendering for product name with image
      ) => (
        <div className="flex items-center">
          <img
            src={row.productImage}
            alt={value}
            className="w-8 h-8 mr-2 rounded"
          />
          {value}
        </div>
      ),
    },
    //price coloumn data
    {
      key: "price",
      label: "Price",
      align: "text-left",
      isNumeric: true, // Specify that this column is numeric for sorting
    },
    //total order coloumn data
    {
      key: "totalOrder",
      label: "Total Order",
      align: "text-left",
      isNumeric: true,
      render: (
        value // Custom rendering for total order
      ) => (
        <span
          style={{
            backgroundColor: "rgba(38, 192, 226, 0.1)",
            borderRadius: "8px",
            padding: "2px 4px",
            display: "inline-block",
            textAlign: "center",
            width: "50px",
            color: "#26C0E2",
          }}
        >
          {value}
        </span>
      ),
    },
    //total amount coloumn data
    {
      key: "totalAmount",
      label: "Total Amount",
      align: "text-left",
      isNumeric: true,
    },
  ];

  return (
    <div className="w-full h-full bg-componentBackground shadow-sm rounded-[10px] p-4 overflow-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold text-black opacity-70">
          Recent Orders
        </h2>
        <ViewAllLink
          menuItems={[
            { label: "View All", to: "/purchase-details" },
            { label: "Option 2" },
            { label: "Option 3", to: "/other-page" },//test 404 page
          ]}
        />
      </div>
      {/* Render the Table component with columns and sorted data */}
      <Table
        columns={columns}
        data={sortedItems}
        requestSort={requestSort}
        sortConfig={sortConfig}
      />
    </div>
  );
};

export default RecentOrders;
