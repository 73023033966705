import React, { useState, useEffect } from "react";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";

const EditCustomer = ({ currentCustomer, onUpdate }) => {
  const today = new Date().toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    ...currentCustomer,
    last_contacted: today, // Initialize last_contacted with today's date
  });

  useEffect(() => {
    if (currentCustomer) {
      setFormData({
        ...currentCustomer,
        last_contacted: today, // Always set last_contacted to today's date when loading
      });
    }
  }, [currentCustomer, today]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(formData); // Pass the updated data back to the parent
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <input
        type="text"
        name="customer_name"
        value={formData.customer_name || ""}
        onChange={handleChange}
        placeholder="Customer Name"
        required
        className="border border-gray-300 rounded p-2 mb-2 w-full"
      />
      <input
        type="text"
        name="contact_number"
        value={formData.contact_number || ""}
        onChange={handleChange}
        required
        placeholder="Contact Number"
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="email"
        name="email"
        value={formData.email || ""}
        onChange={handleChange}
        required
        placeholder="Email"
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="text"
        name="address"
        value={formData.address || ""}
        onChange={handleChange}
        placeholder="Address"
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="text"
        name="city"
        value={formData.city || ""}
        onChange={handleChange}
        required
        placeholder="City"
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="text"
        name="customer_type"
        value={formData.customer_type || ""}
        onChange={handleChange}
        placeholder="Customer Type"
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="date"
        name="last_contacted"
        value={formData.last_contacted}
        onChange={handleChange}
        placeholder="Last Contacted"
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="text"
        name="preferred_products"
        value={formData.preferred_products || ""}
        onChange={handleChange}
        placeholder="Preferred Products"
        className="mb-2 p-2 border border-gray-300"
      />
      <button
        type="submit"
        className="mt-4 w-full bg-actionColor text-white py-2 rounded-[10px] hover:bg-opacity-90 transition duration-150 text-base font-normal flex items-center justify-center gap-2"
      >
        <SaveAsRoundedIcon style={{ fontSize: "20px" }} />
        Update Customer
      </button>
    </form>
  );
};

export default EditCustomer;
