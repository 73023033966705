import React from "react";
import { Link } from "react-router-dom";

const SidebarLink = ({ isCollapsed, icon: Icon, label, path, isActive }) => (
  <Link
    to={path}
    className={`flex items-center p-4 transition-all rounded-md ${
      isActive
        ? "bg-[linear-gradient(90deg,#D1C6FF_0%,rgba(172,169,255,0)_12%)] text-actionColor font-bold"
        : "hover:bg-gray-200 text-fontColor font-medium"
    }`}
  >
    <Icon
      className={`w-6 h-6 transition-colors duration-200 ${
        isActive ? "text-actionColor" : "text-fontColor opacity-50"
      } hover:bg-none`}
    />
    {/*checks if the isCollapsed prop is false (meaning the sidebar is expanded).When isCollapsed is false, it renders the label text alongside the icon. */}
    {!isCollapsed && (
      <span
        className={`ml-4 text-base  transition-colors duration-200 ${
          isActive ? "text-actionColor" : "text-fontColor opacity-70"
        }`}
      >
        {label}
      </span>
    )}
  </Link>
);

export default SidebarLink;
