import React, { useState, useEffect } from "react";
import AddNewProduct from "../components/products/AddNewProduct";
import EditProduct from "../components/products/EditProduct"; // Import EditProduct
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null); // Track the product to edit

  // Load dummy data
  useEffect(() => {
    const loadProducts = async () => {
      try {
        const response = await fetch("/dummydata.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    loadProducts();
  }, []);

  const handleAddProduct = (newProduct) => {
    const updatedProducts = [...products, newProduct];
    setProducts(updatedProducts);
    setIsAddModalOpen(false);
  };

  const handleDeleteProduct = (id) => {
    const updatedProducts = products.filter(
      (product) => product.product_id !== id
    );
    setProducts(updatedProducts);
  };

  const handleEditProduct = (id) => {
    const productToEdit = products.find((product) => product.product_id === id);
    if (productToEdit) {
      setProductToEdit(productToEdit); // Set the product to edit
      setIsEditModalOpen(true); // Open the edit modal
    }
  };

  const handleUpdateProduct = (updatedProduct) => {
    const updatedProducts = products.map((product) =>
      product.product_id === updatedProduct.product_id
        ? updatedProduct
        : product
    );
    setProducts(updatedProducts);
  };

  const toggleAddModal = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Products Page</h2>

      <input
        type="text"
        placeholder="Search Products..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-2 mb-4 border border-gray-300 rounded"
      />

      <button
        onClick={toggleAddModal}
        className="mb-4 p-2 bg-blue-600 text-white rounded flex items-center"
      >
        <AddIcon className="mr-2" /> Add New Product
      </button>

      <table className="min-w-full border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 p-2">Product ID</th>
            <th className="border border-gray-300 p-2">Product Name</th>
            <th className="border border-gray-300 p-2">Category</th>
            <th className="border border-gray-300 p-2">Description</th>
            <th className="border border-gray-300 p-2">Unit Price</th>
            <th className="border border-gray-300 p-2">Stock Quantity</th>
            <th className="border border-gray-300 p-2">Reorder Level</th>
            <th className="border border-gray-300 p-2">Status</th>
            <th className="border border-gray-300 p-2">Date Added</th>
            <th className="border border-gray-300 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {products
            .filter((product) =>
              product.product_name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            )
            .map((product) => (
              <tr key={product.product_id} className="hover:bg-gray-50">
                <td className="border border-gray-300 p-2">
                  {product.product_id}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.product_name}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.product_category}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.description}
                </td>
                <td className="border border-gray-300 p-2">
                  {typeof product.unit_price === "number"
                    ? product.unit_price.toFixed(2)
                    : "N/A"}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.stock_quantity}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.reorder_level}
                </td>
                <td className="border border-gray-300 p-2">{product.status}</td>
                <td className="border border-gray-300 p-2">
                  {product.date_added}
                </td>
                <td className="border border-gray-300 p-2 flex space-x-2">
                  <EditIcon
                    onClick={() => handleEditProduct(product.product_id)}
                    className="cursor-pointer text-blue-600 hover:text-blue-800"
                  />
                  <DeleteIcon
                    onClick={() => handleDeleteProduct(product.product_id)}
                    className="cursor-pointer text-red-600 hover:text-red-800"
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Modal for Add New Product */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-96">
            <AddNewProduct onAdd={handleAddProduct} />
            <button
              onClick={toggleAddModal}
              className="mt-4 p-2 bg-red-600 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Modal for Edit Product */}
      {isEditModalOpen && productToEdit && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-96">
            <EditProduct
              product={productToEdit}
              onUpdate={handleUpdateProduct}
              onClose={toggleEditModal}
            />
            <button
              onClick={toggleEditModal}
              className="mt-4 p-2 bg-red-600 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
