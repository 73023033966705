import React, { useState, useEffect } from "react";
import AddNewCustomer from "../components/store/AddNewCustomer";
import EditCustomer from "../components/store/EditCustomer";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  // Load dummy data
  useEffect(() => {
    const loadCustomers = async () => {
      try {
        const response = await fetch("/dummycustomers.json"); // Fetch from the public directory
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCustomers(data);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    loadCustomers();
  }, []);

  const handleAddCustomer = (newCustomer) => {
    setCustomers((prevCustomers) => [...prevCustomers, newCustomer]);
    setIsAddModalOpen(false); // Close modal after adding customer
  };

  const handleDeleteCustomer = (id) => {
    const updatedCustomers = customers.filter((_, index) => index !== id);
    setCustomers(updatedCustomers);
  };

  const handleEditCustomer = (index) => {
    const customerToEdit = customers[index];
    if (customerToEdit) {
      setCurrentCustomer({ ...customerToEdit, index }); // Store index for updating
      setIsEditModalOpen(true); // Open modal for editing
    }
  };

  const handleUpdateCustomer = (updatedCustomer) => {
    const updatedCustomers = [...customers];
    updatedCustomers[currentCustomer.index] = updatedCustomer; // Update specific customer
    setCustomers(updatedCustomers);
    setIsEditModalOpen(false); // Close modal after updating
    setCurrentCustomer(null); // Reset current customer
  };

  const toggleAddModal = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
    setCurrentCustomer(null); // Reset current customer when modal is closed
  };

  return (
    <div className="h-full flex flex-col gap-4">
      {/* Header container */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6 gap-6">
        <h1 className="text-2xl font-bold text-fontColor">Customer List</h1>

        {/* Search input and Add New button container */}
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-full sm:w-auto">
          {/* Search input */}
          <div className="relative flex-1 sm:max-w-xs">
            <SearchRoundedIcon
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-fontColor opacity-70"
              style={{ fontSize: "1.5rem" }}
            />
            <input
              type="text"
              placeholder="Search Customers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-blue-400"
            />
          </div>

          {/* Add New button */}
          <button
            onClick={toggleAddModal}
            className="px-5 py-2 bg-actionColor text-white rounded-lg flex items-center justify-center hover:bg-opacity-90 transition-colors"
          >
            <AddRoundedIcon className="mr-2" /> Add New Customer
          </button>
        </div>
      </div>

      <table className="min-w-full bg-componentBackground rounded-lg shadow-md">
        <thead>
          <tr>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Customer ID
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Customer Name
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Contact Number
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Email
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Address
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              City
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Customer Type
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Last Contacted
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Preferred Products
            </th>
            <th className="border-b border-gray-200 p-4 text-fontColor opacity-70 text-base font-semibold text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {customers
            .filter((customer) =>
              customer.customer_name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            )
            .map((customer, index) => (
              <tr
                key={index}
                className="hover:bg-gray-50 border-b border-gray-200"
              >
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {index + 1}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.customer_name}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.contact_number}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.email}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.address}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.city}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.customer_type}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.last_contacted}
                </td>
                <td className="p-4 text-sm text-fontColor font-normal text-left">
                  {customer.preferred_products}
                </td>
                <td className="p-4 flex space-x-2">
                  <div className="bg-blue-50 p-2 rounded-full cursor-pointer">
                    <EditIcon
                      onClick={() => handleEditCustomer(index)}
                      className="text-blue-500 hover:text-blue-700 text-[10px]"
                    />
                  </div>
                  <div className="bg-red-50 p-2 rounded-full cursor-pointer">
                    <DeleteIcon
                      onClick={() => handleDeleteCustomer(index)}
                      className="text-red-500 hover:text-red-700 text-[10px]"
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Modal for Add New Customer */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-96">
            <h3 className="text-fontColor text-lg font-bold mb-4">
              Add New Customer
            </h3>
            <AddNewCustomer onAdd={handleAddCustomer} />
            <button
              onClick={toggleAddModal}
              className="mt-2 p-2 w-full bg-red-500 text-white rounded-[10px] hover:bg-opacity-90 transition duration-150 text-base font-normal flex items-center justify-center gap-2"
            >
              <CloseRoundedIcon style={{ fontSize: "20px" }} />
              Close
            </button>
          </div>
        </div>
      )}

      {/* Modal for Edit Customer */}
      {isEditModalOpen && currentCustomer && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 w-96">
            <h3 className="text-fontColor text-lg font-bold mb-4">
              Edit Customer
            </h3>
            <EditCustomer
              currentCustomer={currentCustomer}
              onUpdate={handleUpdateCustomer}
            />
            <button
              onClick={toggleEditModal}
              className="mt-2 p-2 w-full bg-red-500 text-white rounded-[10px] hover:bg-opacity-90 transition duration-150 text-base font-normal flex items-center justify-center gap-2"
            >
              <CloseRoundedIcon style={{ fontSize: "20px" }} />
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Customers;
