import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/sidebar";
import ProfileDetails from "./profile/ProfileDetails"; // Import the ProfileDetails component

function Layout() {
  const [isProfileVisible, setIsProfileVisible] = useState(false);

  const toggleProfile = () => {
    setIsProfileVisible(!isProfileVisible);
  };

  return (
    <div className="relative grid grid-cols-12 h-screen bg-pageBackground">
      {/* Sidebar */}
      <div className="col-span-2 h-full bg-transparent hidden lg:flex">
        <Sidebar toggleProfile={toggleProfile} />
      </div>

      {/* Main content area */}
      <div className="col-span-12 lg:col-span-10 p-4 overflow-auto">
        <Outlet />
      </div>

      {/* Profile Details Slide-In Panel */}
      <ProfileDetails isVisible={isProfileVisible} onClose={toggleProfile} />
    </div>
  );
}

export default Layout;
