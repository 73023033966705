import React, { useState } from "react";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";

const AddNewCustomer = ({ onAdd }) => {
  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];

  const [newCustomer, setNewCustomer] = useState({
    customer_id: "",
    customer_name: "",
    contact_number: "",
    email: "",
    address: "",
    city: "",
    customer_type: "",
    last_contacted: today, // Set default date to today
    preferred_products: "",
    notes: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer({
      ...newCustomer,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd({ ...newCustomer, customer_id: Date.now() }); // Generate a unique ID for the new customer
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <input
        type="text"
        name="customer_name"
        value={newCustomer.customer_name}
        onChange={handleChange}
        required
        placeholder="Customer Name"
        className="mb-2 p-2 border border-gray-300 placeholder-fontColor placeholder-opacity-70"
      />
      <input
        type="text"
        name="contact_number"
        value={newCustomer.contact_number}
        onChange={handleChange}
        required
        placeholder="Contact Number"
        className="mb-2 p-2 border border-gray-300 placeholder-fontColor placeholder-opacity-70"
      />
      <input
        type="email"
        name="email"
        value={newCustomer.email}
        onChange={handleChange}
        required
        placeholder="Email"
        className="mb-2 p-2 border border-gray-300 placeholder-fontColor placeholder-opacity-70"
      />
      <input
        type="text"
        name="address"
        value={newCustomer.address}
        onChange={handleChange}
        placeholder="Address"
        className="mb-2 p-2 border border-gray-300 placeholder-fontColor placeholder-opacity-70"
      />
      <input
        type="text"
        name="city"
        value={newCustomer.city}
        onChange={handleChange}
        required
        placeholder="City"
        className="mb-2 p-2 border border-gray-300 placeholder-fontColor placeholder-opacity-70"
      />
      <input
        type="text"
        name="customer_type"
        value={newCustomer.customer_type}
        onChange={handleChange}
        placeholder="Customer Type"
        className="mb-2 p-2 border border-gray-300 placeholder-fontColor placeholder-opacity-70"
      />
      <input
        type="date"
        name="last_contacted"
        value={newCustomer.last_contacted}
        onChange={handleChange}
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="text"
        name="preferred_products"
        value={newCustomer.preferred_products}
        onChange={handleChange}
        placeholder="Preferred Products"
        className="mb-2 p-2 border border-gray-300 placeholder-fontColor placeholder-opacity-70"
      />
      <button
        type="submit"
        className="mt-4 w-full bg-actionColor text-white py-2 rounded-[10px] hover:bg-opacity-90 transition duration-150 text-base font-normal flex items-center justify-center gap-2"
      >
        <AddBusinessRoundedIcon style={{ fontSize: "20px" }} />
        Add Customer
      </button>
    </form>
  );
};

export default AddNewCustomer;
