import React, { useState } from "react";

const EditProduct = ({ product, onUpdate, onClose }) => {
  const [updatedProduct, setUpdatedProduct] = useState({ ...product });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProduct({
      ...updatedProduct,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate({
      ...updatedProduct,
      date_added: new Date().toISOString().split("T")[0],
    });
    onClose(); // Close modal after updating product
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <input
        type="text"
        name="product_name"
        value={updatedProduct.product_name}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="text"
        name="product_category"
        value={updatedProduct.product_category}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <textarea
        name="description"
        value={updatedProduct.description}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="number"
        name="unit_price"
        value={updatedProduct.unit_price}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="number"
        name="stock_quantity"
        value={updatedProduct.stock_quantity}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <input
        type="number"
        name="reorder_level"
        value={updatedProduct.reorder_level}
        onChange={handleChange}
        required
        className="mb-2 p-2 border border-gray-300"
      />
      <button type="submit" className="p-2 bg-green-600 text-white rounded">
        Update Product
      </button>
    </form>
  );
};

export default EditProduct;
