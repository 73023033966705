import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";

const Inventory = () => {
  const [inventoryLogs, setInventoryLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const loadInventoryLogs = async () => {
      try {
        const response = await fetch("/dummyinventorylogs.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setInventoryLogs(data);
      } catch (error) {
        console.error("Error fetching inventory logs:", error);
      }
    };

    loadInventoryLogs();
  }, []);

  const filteredLogs = inventoryLogs.filter((log) => {
    return log.product_name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Inventory Change Log</h2>

      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="Search by product name..."
          className="border border-gray-300 p-2 rounded w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SearchIcon className="ml-2 cursor-pointer" />
      </div>

      <table className="min-w-full border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 p-2">Log ID</th>
            <th className="border border-gray-300 p-2">Product ID</th>
            <th className="border border-gray-300 p-2">Product Name</th>
            <th className="border border-gray-300 p-2">Change Type</th>
            <th className="border border-gray-300 p-2">Quantity Change</th>
            <th className="border border-gray-300 p-2">Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredLogs.map((log) => (
            <tr key={log.log_id} className="hover:bg-gray-50">
              <td className="border border-gray-300 p-2">{log.log_id}</td>
              <td className="border border-gray-300 p-2">{log.product_id}</td>
              <td className="border border-gray-300 p-2">{log.product_name}</td>
              <td className="border border-gray-300 p-2">{log.change_type}</td>
              <td className="border border-gray-300 p-2">
                <span
                  className={`badge ${
                    log.change_type === "sale" ? "bg-red-500" : "bg-green-500"
                  }`}
                >
                  {log.quantity_change}
                </span>
              </td>
              <td className="border border-gray-300 p-2">{log.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Inventory;
