import React from "react";
import { useLocation } from "react-router-dom";
import SidebarLink from "./sidebarlink";

// Icons
import logo from "../../assets/sidebar_icons/Subtract.png";
import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import TextSnippetRoundedIcon from "@mui/icons-material/TextSnippetRounded";
import WarehouseRoundedIcon from "@mui/icons-material/WarehouseRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import upgradeIcon from "../../assets/sidebar_icons/upgrade.png";

const Sidebar = ({ toggleProfile }) => {
  const location = useLocation();
  const localStorageData = JSON.parse(localStorage.getItem("user"));

  const handleLogout = () => {
    // Logout logic
    localStorage.removeItem("user");
    window.location.href = "/login"; // Redirect to login page after logout
  };

  return (
    <div className="h-screen w-72 bg-sidebarBackground shadow-lg flex flex-col justify-between">
      {/* Logo */}
      <div className="p-4 flex items-center justify-center">
        <img src={logo} alt="Logo" className="w-16" />
      </div>

      {/* Sidebar Links */}
      <div className="flex-1">
        <SidebarLink
          icon={SpaceDashboardRoundedIcon}
          label="Dashboard"
          path="/dashboard"
          isActive={location.pathname === "/dashboard"}
        />
        <SidebarLink
          icon={Inventory2RoundedIcon}
          label="Inventory"
          path="/inventory"
          isActive={location.pathname === "/inventory"}
        />
        <SidebarLink
          icon={TextSnippetRoundedIcon}
          label="Products"
          path="/products"
          isActive={location.pathname === "/products"}
        />
        <SidebarLink
          icon={BusinessCenterRoundedIcon}
          label="Sales"
          path="/sales"
          isActive={location.pathname === "/sales"}
        />
        <SidebarLink
          icon={WarehouseRoundedIcon}
          label="Stores"
          path="/stores"
          isActive={location.pathname === "/stores"}
        />
      </div>

      {/* Upgrade */}
      <div className="flex justify-center mb-4 pb-3">
        <div className="flex items-center justify-between w-[80%] max-w-xs">
          <img src={upgradeIcon} alt="Upgrade" className="w-10 h-10" />
          <button className="bg-actionColor text-white font-semibold text-[12px] py-2 px-8 rounded-[10px] hover:bg-opacity-90">
            Upgrade Now
          </button>
        </div>
      </div>

      {/* Profile section with logout button */}
      <div className="sticky inset-x-0 bottom-0 border-t border-gray-300">
        <div className="flex items-center justify-between bg-white p-3 w-full">
          <div
            className="flex items-start gap-2 cursor-pointer w-full"
            onClick={toggleProfile}
          >
            {/* Profile Image / Initials */}
            <div className="flex-shrink-0 h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
              {localStorageData.imageUrl ? (
                <img
                  alt="Profile"
                  src={localStorageData.imageUrl}
                  className="h-full w-full rounded-full object-cover"
                />
              ) : (
                <span className="text-sm font-bold text-fontColor">
                  {localStorageData.firstName?.[0]}
                  {localStorageData.lastName?.[0]}
                </span>
              )}
            </div>

            {/* Name and Email */}
            <div className="flex-grow">
              <p className="text-xs text-black truncate">
                <strong className="block font-semibold text-ellipsis overflow-hidden whitespace-nowrap">
                  {localStorageData.firstName + " " + localStorageData.lastName}
                </strong>
                <span className="text-[10px] text-gray-400 opacity-70 text-ellipsis overflow-hidden whitespace-nowrap">
                  {localStorageData.email}
                </span>
              </p>
            </div>
          </div>

          {/* Logout Icon */}
          <div className="flex-shrink-0 ml-2">
            <LogoutRoundedIcon
              onClick={handleLogout}
              className="cursor-pointer text-fontColor opacity-60 hover:text-red-600"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
